import { Text, View } from "@react-pdf/renderer";

import { styles } from ".";
import { IntroductionInferredType } from "~/components";

interface IntroductionPdfProps {
  introductionValues: IntroductionInferredType;
}

export const IntroductionPdf = ({ introductionValues }: IntroductionPdfProps) => {
  return (
    <View wrap={false} style={[styles.section]}>
      <Text style={styles.text}>{introductionValues.introduction}</Text>
    </View>
  );
};