import { Text, View } from "@react-pdf/renderer";

import { YouInferredType } from "~/components";
import { styles } from ".";

interface YouPdfProps {
  youValues: {
    fullName: string;
    jobTitle?: string;
  };
}

export const YouPdf = ({ youValues }: YouPdfProps) => {
  const { fullName, jobTitle } = youValues;
  const [firstName, ...otherNames] = fullName.split(" ");

  return (
    <View wrap={false} style={[styles.section]}>
      {firstName && (
        <Text
          style={[
            styles.cvTitle,
            {
              fontWeight: 800,
            },
          ]}
        >
          {firstName}
        </Text>
      )}
      {!!otherNames.length && (
        <Text
          style={[
            styles.cvTitle,
            {
              marginBottom: 8,
              fontWeight: 500,
            },
          ]}
        >
          {otherNames.join(" ")}
        </Text>
      )}
      {jobTitle && (
        <Text style={[styles.capitalize, styles.muted, { fontSize: 10 }]}>
          {jobTitle}
        </Text>
      )}
    </View>
  );
};
