import { Text, View } from "@react-pdf/renderer";

import { styles } from ".";
import { EmploymentInferredType } from "~/components";
import { getDisplayDateFormat } from "~/utils";

interface EmploymentPdfProps {
  employmentValues: EmploymentInferredType[];
}

export const EmploymentPdf = ({ employmentValues }: EmploymentPdfProps) => {
  return (
    <View style={styles.section}>
      <Text style={styles.mainSectionHeading}>Employment History</Text>
      {employmentValues?.map(
        (
          { jobTitle, company, location, startDate, endDate, responsibilities },
          idx
        ) => (
          <View wrap={false} key={idx} style={styles.subSection}>
            <View style={styles.textRow}>
              <Text style={styles.text}>
                {jobTitle} {!!company ? "at " : ""}
              </Text>
              {!!company && (
                <Text style={[styles.bold, styles.text]}>{company}</Text>
              )}
              {!!location && (
                <Text style={styles.text}>&nbsp;-&nbsp;{location}</Text>
              )}
            </View>
            {!!(startDate || endDate) && (
              <Text style={[styles.text, styles.muted]}>
                {startDate && getDisplayDateFormat(startDate)}
                {endDate && ` - ${getDisplayDateFormat(endDate)}`}
              </Text>
            )}
            {!!responsibilities?.length ? (
              responsibilities.length === 1 ? (
                <View style={{ paddingTop: 4 }}>
                  <Text style={styles.text}>
                    {responsibilities[0].description}
                  </Text>
                </View>
              ) : (
                <View style={styles.ul}>
                  {responsibilities.map(
                    ({ id, description }) =>
                      !!description && (
                        <Text key={id} style={styles.text}>
                          • {description}
                        </Text>
                      )
                  )}
                </View>
              )
            ) : null}
          </View>
        )
      )}
    </View>
  );
};
