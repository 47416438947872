import { Image, View } from "@react-pdf/renderer";

import { sidebarStyles, styles } from ".";
import { useEffect, useState } from "react";

interface ImagePdfProps {
  imageUrl: string;
}

export const ImagePdf = ({ imageUrl }: ImagePdfProps) => {
  const [blobUrl, setBlobUrl] = useState<string>();

  useEffect(() => {
    const getBlobUrl = (imageUrl: string) => {
      const byteCharacters = Buffer.from(imageUrl, "base64");
      const blob = new Blob([byteCharacters], { type: "image/jpg" });
      return URL.createObjectURL(blob);
    };

    const blobUrl = getBlobUrl(imageUrl);
    setBlobUrl(blobUrl);

    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, []);

  return (
    <View style={[styles.section, { textAlign: "center" }]}>
      {blobUrl && <Image src={blobUrl} />}
    </View>
  );
};
