import { Text, View } from "@react-pdf/renderer";

import { sidebarStyles, styles } from ".";
import { Attribute } from "../modals";

interface AttributesPdfProps {
  attributeList: Attribute[];
}

export const AttributesPdf = ({ attributeList }: AttributesPdfProps) => {
  return (
    <View wrap={false} style={styles.section}>
      <Text style={sidebarStyles.sidebarHeading}>Personal attributes</Text>
      <View wrap={false} style={styles.subSection}>
        {!!attributeList?.length ? (
          attributeList.length === 1 ? (
            <View>
              <Text style={styles.text}>{attributeList[0].description}</Text>
            </View>
          ) : (
            <View style={styles.ul}>
              {attributeList.map(
                ({ id, description }) =>
                  !!description && (
                    <Text key={id} style={styles.text}>
                      • {description}
                    </Text>
                  )
              )}
            </View>
          )
        ) : null}
      </View>
    </View>
  );
};
