import { Text, View } from "@react-pdf/renderer";

import { sidebarStyles, styles } from ".";
import { SKILL_LEVELS } from "~/models";

interface SkillsPdfProps {
  skills: Skill[];
}

export type Skill = {
  id: string;
  description: string;
  level: number;
};

export const SkillsPdf = ({ skills }: SkillsPdfProps) => {
  return (
    <View wrap={false} style={styles.section}>
      <Text style={sidebarStyles.sidebarHeading}>Skills</Text>
      {skills.map(({ id, description, level }) => (
        <View key={id} style={styles.subSection}>
          <Text style={styles.text}>{description}</Text>
          <View wrap={false} style={[styles.textRow, styles.subSection]}>
            {SKILL_LEVELS.map((_, idx) =>
              level > idx ? (
                <Text key={idx} style={sidebarStyles.skillLevelComplete} />
              ) : (
                <Text key={idx} style={sidebarStyles.skillLevelIncomplete} />
              )
            )}
          </View>
        </View>
      ))}
    </View>
  );
};
