import { Text, View } from "@react-pdf/renderer";

import { sidebarStyles, styles } from ".";
import { ContactInferredType } from "~/components";

interface ContactPdfProps {
  contactValues: ContactInferredType;
}

export const ContactPdf = ({ contactValues }: ContactPdfProps) => {
  const { emailAddress, phoneNumber, postalAddress } = contactValues || {};

  return (
    <View style={[styles.section, { textAlign: "center" }]}>
      <Text style={sidebarStyles.contactText}>{emailAddress}</Text>
      {!!phoneNumber && <Text style={sidebarStyles.contactText}>{phoneNumber}</Text>}
      {!!postalAddress && <Text style={sidebarStyles.contactText}>{postalAddress}</Text>}
    </View >
  );
};