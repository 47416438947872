import { Text, View } from "@react-pdf/renderer";

import { styles } from ".";
import { VolunteeringInferredType } from "~/components";
import { getDisplayDateFormat } from "~/utils";

interface VolunteeringPdfProps {
  volunteeringValues: VolunteeringInferredType[];
}

export const VolunteeringPdf = ({
  volunteeringValues,
}: VolunteeringPdfProps) => {
  return (
    <View style={styles.section}>
      <Text style={styles.mainSectionHeading}>Volunteering Experience</Text>
      {volunteeringValues?.map(
        (
          {
            roleTitle,
            organisation,
            location,
            startDate,
            endDate,
            responsibilities,
          },
          idx
        ) => (
          <View wrap={false} key={idx} style={styles.subSection}>
            <View style={styles.textRow}>
              <Text style={styles.text}>{roleTitle} at </Text>
              {!!organisation && (
                <Text style={[styles.bold, styles.text]}>{organisation}</Text>
              )}
              {!!location && (
                <Text style={styles.text}>&nbsp;-&nbsp;{location}</Text>
              )}
            </View>
            {!!(startDate || endDate) && (
              <Text style={[styles.text, styles.muted]}>
                {startDate && getDisplayDateFormat(startDate)}
                {endDate && ` - ${getDisplayDateFormat(endDate)}`}
              </Text>
            )}
            {!!responsibilities?.length ? (
              responsibilities.length === 1 ? (
                <View>
                  <Text style={styles.text}>
                    {responsibilities[0].description}
                  </Text>
                </View>
              ) : (
                <View style={styles.ul}>
                  {responsibilities.map(
                    ({ id, description }) =>
                      !!description && (
                        <Text key={id} style={styles.text}>
                          • {description}
                        </Text>
                      )
                  )}
                </View>
              )
            ) : null}
          </View>
        )
      )}
    </View>
  );
};
