import { Text, View } from "@react-pdf/renderer";

import { styles } from ".";
import { CertificateInferredType } from "~/components";

interface CertificatePdfProps {
  certificateValues: CertificateInferredType[];
}

export const CertificatePdf = ({ certificateValues }: CertificatePdfProps) => {
  return (
    <View style={styles.section}>
      <Text style={styles.mainSectionHeading}>Certifications</Text>
      <View style={styles.row}>
        {certificateValues?.map(({ title, authority, licenseNumber }, idx) => (
          <View wrap={false} key={idx} style={[styles.col6, styles.subSection]}>
            <Text style={[styles.bold, styles.text]}>{title}</Text>
            {!!authority && <Text style={[styles.text]}>{authority}</Text>}
            {!!licenseNumber && <Text style={[styles.text, styles.muted]}>{licenseNumber}</Text>}
          </View>
        ))}
      </View>
    </View>
  );
};