import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    width: "100vw",
    height: "80vh",
    flexDirection: "row",
  },
  mainContent: {
    width: "65%",
    paddingVertical: 32,
    paddingHorizontal: 40,
  },
  section: {
    marginBottom: 20,
  },
  subSection: {
    marginBottom: 8,
  },
  mainSectionHeading: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1,
    marginBottom: 8,
  },
  cvTitle: {
    fontSize: 30,
    lineHeight: 0.9,
    textTransform: "capitalize",
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
  },
  textRow: {
    flexDirection: "row",
  },
  ul: {
    paddingTop: 4,
    paddingLeft: 10,
  },
  muted: {
    color: "#6c757d",
  },
  bold: {
    fontWeight: "bold",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  lowercase: {
    textTransform: "lowercase",
  },
  row: {
    position: "absolute",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    flexBasis: "50%",
    marginTop: 20,
  },
  col6: {
    width: "50%",
    paddingBottom: 10,
  },
});

export const sidebarStyles = StyleSheet.create({
  sidebarColumn: {
    width: "35%",
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: "#f3f3f3",
  },
  sidebarHeading: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: 5,
  },
  divider: {
    width: "35vw",
    marginHorizontal: -20,
    marginBottom: 24,
    borderBottom: "1px solid #DCDDDD",
  },
  contactText: {
    fontSize: 10,
    lineHeight: 2,
  },
  skillLevelIncomplete: {
    backgroundColor: "#BABDBC",
    borderRadius: 5,
    width: "15%",
    height: 5,
    marginRight: 3,
  },
  skillLevelComplete: {
    backgroundColor: "#5B6161",
    borderRadius: 5,
    width: "15%",
    height: 5,
    marginRight: 3,
  },
});
