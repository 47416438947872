import { useEffect, useState } from "react";
import { PDFViewer, View, Document, Font, Page } from "@react-pdf/renderer";

import { Meta } from "~/ui";
import { Routes, Stored } from "~/models";
import {
  Attribute,
  AttributesInferredType,
  CertificateInferredType,
  ContactInferredType,
  EducationInferredType,
  EmploymentInferredType,
  IntroductionInferredType,
  SkillsInferredType,
  VolunteeringInferredType,
  YouInferredType,
} from "~/components";

import {
  AttributesPdf,
  CertificatePdf,
  ContactPdf,
  EducationPdf,
  EmploymentPdf,
  ImagePdf,
  IntroductionPdf,
  sidebarStyles,
  Skill,
  SkillsPdf,
  styles,
  VolunteeringPdf,
  YouPdf,
} from "~/components/cv-pdf";

import MontserratLight from "~/assets/fonts/Montserrat-Light.ttf";
import MontserratMedium from "~/assets/fonts/Montserrat-Medium.ttf";
import MontserratBold from "~/assets/fonts/Montserrat-Bold.ttf";
import MontserratExtraBold from "~/assets/fonts/Montserrat-ExtraBold.ttf";

Font.register({
  family: "Montserrat",
  format: "truetype",
  fonts: [
    { src: MontserratLight, fontWeight: 300 },
    { src: MontserratMedium, fontWeight: 500 },
    { src: MontserratBold, fontWeight: 700 },
    { src: MontserratExtraBold, fontWeight: 800 },
  ],
});

interface Data {
  you: YouInferredType | null;
  introduction: IntroductionInferredType | null;
  employment: EmploymentInferredType[] | null;
  volunteering: VolunteeringInferredType[] | null;
  education: EducationInferredType[] | null;
  certification: CertificateInferredType[] | null;
  imageUrl: string | null;
  contact: ContactInferredType | null;
  skills: SkillsInferredType | null;
  attributes: AttributesInferredType | null;
}

export default function CVDownload(): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Data | null>(null);

  useEffect(() => {
    const you: YouInferredType | null = JSON.parse(
      localStorage.getItem(Stored.You) || "null"
    );
    const introduction: IntroductionInferredType | null = JSON.parse(
      localStorage.getItem(Stored.Introduction) || "null"
    );
    const employment: EmploymentInferredType[] | null = JSON.parse(
      localStorage.getItem(Stored.Employment) || "null"
    );
    const imageUrl: string | null = JSON.parse(
      localStorage.getItem(Stored.Image) || "null"
    );
    const contact: ContactInferredType | null = JSON.parse(
      localStorage.getItem(Stored.Contact) || "null"
    );
    const skills: SkillsInferredType | null = JSON.parse(
      localStorage.getItem(Stored.Skills) || "null"
    );
    const volunteering: VolunteeringInferredType[] | null = JSON.parse(
      localStorage.getItem(Stored.Volunteering) || "null"
    );
    const education: EducationInferredType[] | null = JSON.parse(
      localStorage.getItem(Stored.Education) || "null"
    );
    const certification: CertificateInferredType[] | null = JSON.parse(
      localStorage.getItem(Stored.Certificate) || "null"
    );
    const attributes: AttributesInferredType[] | null = JSON.parse(
      localStorage.getItem(Stored.Attributes) || "null"
    );

    setData(() => ({
      you,
      introduction,
      employment,
      imageUrl,
      contact,
      skills,
      volunteering,
      education,
      certification,
      attributes,
    }));

    setIsLoading(false);
  }, []);

  if (isLoading && !data) {
    return null;
  }

  const {
    you,
    introduction,
    employment,
    volunteering,
    education,
    certification,
    imageUrl,
    contact,
    skills,
    attributes,
  } = data as Data;

  return (
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <Document title="CV Download">
        <Page size="A4" style={styles.page}>
          <View style={styles.mainContent}>
            {you && <YouPdf youValues={you} />}
            {introduction && (
              <IntroductionPdf introductionValues={introduction} />
            )}
            {employment && <EmploymentPdf employmentValues={employment} />}
            {volunteering && (
              <VolunteeringPdf volunteeringValues={volunteering} />
            )}
            {education && <EducationPdf educationValues={education} />}
            {certification && (
              <CertificatePdf certificateValues={certification} />
            )}
          </View>
          <View style={sidebarStyles.sidebarColumn}>
            {imageUrl && <ImagePdf imageUrl={imageUrl} />}
            {contact && <ContactPdf contactValues={contact} />}

            {(imageUrl || contact) && (
              <View style={sidebarStyles.divider}></View>
            )}

            {skills && <SkillsPdf skills={(skills || []) as Skill[]} />}
            {attributes && (
              <AttributesPdf
                attributeList={(attributes || []) as Attribute[]}
              />
            )}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export const Head = () => {
  return <Meta title="Download your CV" url={Routes.CvDownload} />;
};
